import { Howl } from 'howler'

export default class HowlerAudioStreamer {
  constructor() {
    this.audioChunksQueue = []
  }

  addAudioChunkToQueue(audioChunk) {
    this.audioChunksQueue.push(audioChunk)
  }

  playFirstAudioChunk() {
    this.play(this.audioChunksQueue[0], 1)
  }

  play(audio, nextIndex) {
    const sound = new Howl({
      src: ['data:audio/mpeg;base64,' + audio],
      format: ['mp3'],
    })
    sound.on('end', () => {
      this.playNextAudioChunk(nextIndex)
    })

    sound.play()
  }

  playNextAudioChunk(index) {
    console.log(`playing ${index}`)
    const audioChunk = this.audioChunksQueue[index]

    if (audioChunk) {
      this.play(audioChunk, index + 1)
    } else {
      this.audioChunksQueue = []
    }
  }
}
