import { Controller } from "@hotwired/stimulus"
import { animation } from "../lottie_animations/assista"

// Connects to data-controller="lottie-animation"
export default class extends Controller {
  static targets = ["container", "autoplay"]
  connect() {
    this.lootieAnimation = bodymovin.loadAnimation({
      container: this.containerTarget,
      renderer: 'svg',
      loop: true,
      autoplay: this.hasAutoplayTarget,
      animationData: animation,
    })
  }

  play() {
    this.lootieAnimation.play()
  }

  stop() {
    this.lootieAnimation.stop()
  }
}
