import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["buttonWrapper"];

    connect() {
        document.addEventListener('turbo:before-stream-render', this.beforeStreamUpdate.bind(this));
    }

    disconnect() {
        document.removeEventListener('turbo:before-stream-render', this.beforeStreamUpdate.bind(this));
    }

    beforeStreamUpdate(event) {
        if (event.target.target === 'scraper-links') {
            if (this.hasButtonWrapperTarget) {
                this.buttonWrapperTarget.classList.add('hidden')
            }
            document.querySelector("#url").setAttribute("readonly", "readonly");
        } else if (document.querySelector("#url")) {
            event.preventDefault();
            location.reload();
        }
    }

    makeItShine(event){
        let inputElement = event.currentTarget;
        let parentForm   = inputElement.closest('form');
        if (parentForm) {
            let urlInput = parentForm.querySelector('input#url');
            if (urlInput && urlInput.value) {
                inputElement.classList.add("moving-shine-effect");
            }
        }
    }
}
