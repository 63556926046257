import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["modal"];

    show() {
        this.modalTarget.style.display = "flex";
    }

    hide() {
        this.modalTarget.style.display = "none";
        this.resetModalContent();
    }

    resetModalContent() {
        const turboFrame = document.getElementById('document-modal-content');
        if (turboFrame) {
            turboFrame.innerHTML = 'Loading...';
        }
    }
}
