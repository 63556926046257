// Entry point for the build script in your package.json
import '@hotwired/turbo-rails'
import './controllers'
import './turbo_streams'
import '@fortawesome/fontawesome-free/js/all'
import 'flowbite/dist/flowbite.turbo.js'
import './hotwireAnimate'
import * as ActiveStorage from '@rails/activestorage'
ActiveStorage.start()

import LocalTime from 'local-time'
LocalTime.start()
