import { Controller } from '@hotwired/stimulus';
import { headers } from '../utils';
import Toastify from "toastify-js";

export default class extends Controller {
    static targets = ["checkbox"]

    connect() {
        this.updateMainCheckbox();
    }

    toggleLinks(event) {
        this.updateMainCheckbox();
    }

    toggleAll(event) {
        const mainCheckbox = event.target;
        const isChecked    = mainCheckbox.checked;
        this.checkboxTargets.forEach((checkbox) => {
            checkbox.checked = isChecked;
        });
        this._toggleActionDropdown(isChecked);
    }

    updateMainCheckbox() {
        const allChecked   = this.checkboxTargets.every((checkbox) => checkbox.checked);
        const noneChecked  = this.checkboxTargets.every((checkbox) => !checkbox.checked);
        const mainCheckbox = this.element.querySelector('#main-box');
        mainCheckbox.checked       = allChecked;
        mainCheckbox.indeterminate = !allChecked && !noneChecked;
        this._toggleActionDropdown(!noneChecked);
    }

    _toggleActionDropdown(showDropdown) {
        const actionDropdown = this.element.querySelector('#action-dropdown');
        if (showDropdown) {
            actionDropdown.classList.remove('hidden');
        } else {
            actionDropdown.classList.add('hidden');
        }
    }

    applyAction(event) {
        event.preventDefault();
        const actionType    = event.target.getAttribute('data-action-type');
        const url           = this.element.querySelector('#action-dropdown').getAttribute('data-url');
        const checkedValues = this.checkboxTargets.filter(checkbox => checkbox.checked).map(checkbox => checkbox.value);

        if (actionType === 'destroy' && !confirm('Are you sure you want to delete the selected documents?')) {
            return;
        }

        this.showLoadingIndicator(true);

        const payload = {
            action_type: actionType,
            section_ids: checkedValues
        };

        fetch(url, {
            method: 'POST',
            headers: headers(),
            body: JSON.stringify(payload)
        })
            .then(response => {
                if (response.ok) {
                    this.setLoadingComplete();
                    window.location.reload();
                } else {
                    this.showLoadingIndicator(false);

                    Toastify({
                      text: 'Something went wrong. Please try again later!',
                      duration: 15000,
                      close: true,
                      gravity: 'bottom',
                      position: 'right',
                      style: {
                        background: 'linear-gradient(to right, rgba(244,67,54,0.8), rgba(158,11,0,1))',
                        color: 'white',
                        borderRadius: '8px',
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                      },
                      stopOnFocus: true,
                    }).showToast()
                }
            })
    }

    showLoadingIndicator(show) {
        const loadingIndicator = this.element.parentElement.querySelector('#loading-indicator');
        const loadingText      = this.element.parentElement.querySelector('#loading-text');

        let interval;
        if (show) {
            let percentage = 0;
            loadingIndicator.classList.remove('hidden');
            clearInterval(interval);

            interval = setInterval(() => {
                if (percentage < 99) {
                    percentage += 1;
                    loadingText.textContent = `${percentage}%`;
                } else {
                    clearInterval(interval);
                }
            }, 500);
        } else {
            clearInterval(interval);
            loadingIndicator.classList.add('hidden');
        }
    }

    setLoadingComplete() {
        const loadingText       = this.element.parentElement.querySelector('#loading-text');
        loadingText.textContent = '100%';
    }
}
