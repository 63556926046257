import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="textarea"
export default class extends Controller {
  static targets = ["textarea", "submitBtn"]

  connect() {
    this.updateElements()
  }

  updateHeight() {
    this.updateElements()
    this.submitBtnTarget.disabled = !this.textareaTarget.value
  }

  updateElements() {
    this.updateElementStyle(this.textareaTarget)
    if (this.textareaTarget.parentNode.classList.contains('field_with_errors')) {
      this.updateElementStyle(this.textareaTarget.parentNode)
    }
  }

  updateElementStyle(element) {
    element.style.maxHeight = '200px'
    element.style.height = '1px'
    element.style.height = `${this.textareaTarget.scrollHeight}px`
  }

  submitForm(event) {
    if (event.key === 'Enter' && !event.shiftKey) {
      this.submitBtnTarget.click()
    }
  }
}
