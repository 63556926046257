import { StreamActions } from '@hotwired/turbo'
import AudioStreamer from '../AudioStreamer'
import HowlerAudioStreamer from '../HowlerAudioStreamer'

// https://github.com/kopiro/siriwave

// const audioStreamer = new AudioStreamer()
const howlerAudioStreamer = new HowlerAudioStreamer()

StreamActions.block_audio = function () {
  const sentence = this.getAttribute('sentence')
  console.log(sentence)
  // audioStreamer.addAudioToQueue(chunk)

  howlerAudioStreamer.addAudioChunkToQueue(sentence)
  if (howlerAudioStreamer.audioChunksQueue.length === 1) {
    howlerAudioStreamer.playFirstAudioChunk()
  }
}

// https://github.com/kopiro/siriwave

// include ActionView::RecordIdentifier
// include Turbo::Streams::ActionHelper
// include Turbo::Streams::StreamName

// reply = Reply.last
// audio_str = Base64.strict_encode64(reply.audio.download)
// content = turbo_stream_action_tag(:block_audio, chunk: audio_str)
// ActionCable.server.broadcast(dom_id(reply.block, :audio), content)
