import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="upload-progress"
export default class extends Controller {
  static targets = ["form", "progress"]

  showFakeProgress() {
    this.formTarget.classList.add("hidden")
    this.progressTarget.classList.remove("hidden")
  }
}
