import React from "react"
import CarbonConnectComponent from './CarbonConnectComponent'

const App = ({customerId}) => {

  return (
   <div>
     <CarbonConnectComponent customerId={customerId} />
   </div>
  )
}
export default App
