import { Controller } from '@hotwired/stimulus'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from '../components/App'

// Connects to data-controller="react"
export default class extends Controller {
  static values = {
    customerId: String,
  }
  connect() {
    const root = ReactDOM.createRoot(document.getElementById('app'))
    root.render(<App customerId={this.customerIdValue} />)
  }
}
