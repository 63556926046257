import { Controller } from '@hotwired/stimulus'
import Prism from '../prism'

// Connects to data-controller="code-block"
export default class extends Controller {
  static targets = ['codeBlock']
  connect() {
    window.Prism = window.Prism || {}
    Prism.manual = true
  }
  codeBlockTargetConnected(element) {
    var snippet = element.innerHTML.replace(/</g, '&lt;')
    snippet = snippet.replace(/ /g, '&nbsp;')
    var code = '<pre class="language-html"><code>' + snippet + '</pre></code>'
    element.innerHTML = ''
    element.insertAdjacentHTML('afterend', code)

    if (window.Prism) {
      Prism.highlightAll(false)
    }
  }
}
