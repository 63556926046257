import { Controller } from '@hotwired/stimulus'
import { progressDone } from '../embed_progress_done'

// Connects to data-controller="fake-progress"
export default class extends Controller {
  static targets = ['loader']

  connect() {
    const string = this.loaderTarget.getAttribute('x-data')
    const regex = /"percent"\s*:\s*(\d+)/
    const match = string.match(regex)
    let percentage = match ? parseInt(match[1]) : 0

    window.setInterval(() => {
      if (percentage < 99) {
        percentage = percentage + 1
        this.loaderTarget.setAttribute('x-data', `{ circumference: 50 * 2 * Math.PI, percent: ${percentage} }`)
      }
    }, 1000)

    if (this.loaderTarget.dataset.poolUrl) {
      window.setInterval(() => {
        fetch(this.loaderTarget.dataset.poolUrl)
          .then(resp => resp.json())
          .then(response => {
            if (response.ongoing === false) {
              progressDone()
            }
          })
      }, 10000)
    }
  }
}
