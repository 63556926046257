import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroller"
export default class extends Controller {
  static targets = ["triggerToBottom", "parentToBottom"]
  connect() {
  }

  triggerToBottomTargetConnected(element) {
    window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'})
  }

  parentToBottomTargetConnected(element) {
    // element.parentNode.scrollIntoView({ block: 'end', behavior: 'smooth' })
    // element.parentNode.scrollBy(0, 500)

    this.element.scrollTo({ top: this.element.scrollHeight, behavior: 'smooth' })
  }
}
