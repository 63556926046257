import Toastify from 'toastify-js'

export const progressDone = () => {
  const progressContainer = document.getElementById('progress-container')
  const progressParent = document.getElementById('progress-parent')
  const progressLoad = document.getElementById('progress-load')

  progressLoad.setAttribute('x-data', `{ circumference: 50 * 2 * Math.PI, percent: 100 }`)

  if (!progressContainer.classList.contains('animate__fadeOut')) {
    progressContainer.addEventListener('animationend', (event) => {
      const div = document.createElement('div')
      const i = document.createElement('i')
      const p = document.createElement('p')

      // Show documents published message
      i.setAttribute('class', 'fa-solid fa-square-check text-success text-4xl')
      p.textContent = 'Documents published!'
      p.setAttribute('class', 'grow-0')
      div.setAttribute('class', 'flex gap-3 items-center justify-center animate__animated animate__fadeIn')

      div.appendChild(i)
      div.appendChild(p)
      progressContainer.innerHTML = ''
      progressParent.insertBefore(div, progressContainer)

      // Showing the toast
      Toastify({
        text: 'Documents published successfully.',
        duration: 15000,
        close: true,
        gravity: 'bottom',
        position: 'right',
        style: {
          background: '#0d6efd',
          color: 'white',
        },
        stopOnFocus: true,
      }).showToast()

      // Reloading the Section index Turbo Frame
      const sectionsFrame = document.querySelector('turbo-frame#sections')
      const src = sectionsFrame.src
      sectionsFrame.src = src
    })
    progressContainer.classList.add('animate__animated', 'animate__fadeOut')
  }
}
