import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="audio-player"
export default class extends Controller {
  static targets = ['audioFile', 'playButton']
  connect() {
    this.playing = false
    if (this.hasAudioFileTarget && this.audioFileTarget.dataset.autoplay === "true") {
      this.audioFileTarget.play()
    }
  }
  play() {
    if (!this.hasPlayButtonTarget) { return }

    if (this.playing) {
      this.playing = false
      this.pause()
    } else {
      this.playing = true
      this.playButtonTarget.innerHTML = '<i class="fa-solid fa-stop text-sm"></i>'
      this.audioFileTarget.play().then(() => {})
      this.audioFileTarget.onended = (event) => {
        this.playButtonTarget.innerHTML = '<i class="fa-solid fa-play text-sm"></i>'
      }
    }
  }

  pause() {
    this.audioFileTarget.pause()
    this.playButtonTarget.innerHTML = '<i class="fa-solid fa-play text-sm"></i>'
  }
}
