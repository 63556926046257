import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="live-time"
export default class extends Controller {
  static targets = ["time"]
  connect() {
    this.startTime()
  }

  startTime() {
    var today = new Date()
    var timeString = today.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })

    this.timeTarget.innerHTML = timeString.toLowerCase().replace(/\s/g, '')

    var t = setTimeout(() => {
      this.startTime()
    }, 500)
  }
}
