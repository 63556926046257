import { Controller } from '@hotwired/stimulus';
import ClassWatcher from '../classWatcher';

// Connects to data-controller="sidebar"
export default class extends Controller {
  static targets = ['sidebar'];
  connect() {
    new ClassWatcher(
      this.sidebarTarget,
      '-translate-x-full',
      this.showSidebar,
      this.hideSidebar
    );
  }

  showSidebar() {
    const backdrop = document.getElementById('sidebar-backdrop');
    if (backdrop) {
      backdrop.classList.add('-translate-x-full');
      backdrop.classList.remove('transform-none');
    }
  }

  hideSidebar() {
    const backdrop = document.getElementById('sidebar-backdrop');
    if (backdrop) {
      backdrop.classList.remove('-translate-x-full');
      backdrop.classList.add('transform-none');
    }
  }
}
