import { Controller } from '@hotwired/stimulus'
import Toastify from 'toastify-js'

// Connects to data-controller="toastify"
export default class extends Controller {
  static targets = ["container"]
  connect() {
    const backgroundColors = {
      alert: 'linear-gradient(to right, rgba(244,67,54,0.8), rgba(158,11,0,1))',
      error: 'linear-gradient(to right, rgba(244,67,54,0.8), rgba(158,11,0,1))',
      notice: 'linear-gradient(to right, rgba(58,65,111,0.8), rgba(20,23,39,1))',
    }
    JSON.parse(this.containerTarget.dataset.flashMessages).forEach((flashMessage) => {
      const [type, message] = flashMessage
      Toastify({
        text: message,
        duration: 15000,
        close: true,
        gravity: 'bottom',
        position: 'right',
        style: {
          background: backgroundColors[type],
          color: type === 'warning' ? 'black' : 'white',
          borderRadius: "8px",
          display: 'flex',
          gap: '10px',
          alignItems: 'center'
        },
        stopOnFocus: true,
      }).showToast()
    })
  }
}
