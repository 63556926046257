import { Controller } from '@hotwired/stimulus'
import MediaStreamRecorder, { invokeSaveAsDialog } from 'recordrtc'
import { DirectUpload } from '@rails/activestorage'
import { MakePostRequest } from '../utils'

// Connects to data-controller="audio-record"
export default class extends Controller {
  static targets = ['microphone', 'form', 'button', 'field', 'typing', 'wave']

  connect() {
    this.recording = false
    this.recorder = null
    this.chunks = []
    this.localStream = null

    document.addEventListener('keydown', this.spaceDown)
    document.addEventListener('keydown', this.stopScroll)
    document.addEventListener('keyup', this.spaceUp)
  }

  disconnect() {
    document.removeEventListener('keydown', this.spaceDown)
    document.removeEventListener('keydown', this.stopScroll)
    document.removeEventListener('keyup', this.spaceUp)
  }

  spaceDown = (e) => {
    if (this.typingTarget !== document.activeElement) {
      if (!this.recording && (e.key == ' ' || e.code == 'Space' || e.keyCode == 32)) {
        this.startRecording()
      }
    }
  }

  spaceUp = (e) => {
    if (this.typingTarget !== document.activeElement) {
      if (this.recording && (e.key == ' ' || e.code == 'Space' || e.keyCode == 32)) {
        this.stopRecording()
      }
    }
  }

  stopScroll(e) {
    if (e.keyCode == 32 && e.target == document.body) {
      e.preventDefault()
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }

  buttonSwitch() {
    if(this.recording) {
      this.stopRecording()
    } else {
      this.startRecording()
    }
  }

  setMicrophoneColor(color) {
    this.microphoneTargets.map((element) => {
      this.setIcon(element, color)
      element.style.color = color
    })
  }

  setIcon(element, color) {
    if (color === 'red') {
      element.innerHTML = '<i class="fa-solid fa-stop text-sm"></i>'
    } else {
      element.innerHTML = '<i class="fa-solid fa-microphone-lines"></i>'
    }
  }

  startRecording() {
    this.setMicrophoneColor('red')
    var config = {
      mimeType: 'audio/webm',
      audioBitsPerSecond: 256 * 8 * 1024,
      checkForInactiveTracks: true,
      timeSlice: 1000,
      ondataavailable: () => {
        this.chunks.push(event.data)
      }
    }

    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      this.recorder = new MediaStreamRecorder(stream, config)

      this.recorder.startRecording()
      this.localStream = stream
      this.recording = true

      const audioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext || window.msAudioContext
      let context
      try {
        context = new audioContext()
      } catch (e) {
        console.log('not support AudioContext')
      }

      const audioInput = context.createMediaStreamSource(stream)
      var binaryData = []
      binaryData.push(stream)
      var analyser = context.createAnalyser()
      audioInput.connect(analyser)

      // this.drawSpectrum(analyser)
    })
  }

  stopRecording() {
    this.setMicrophoneColor('')
    this.recorder.stopRecording(() => {
      let file = new File([this.recorder.getBlob()], 'reply.webm', {
        type: 'audio/webm',
        lastModified: new Date().getTime(),
      })

      let container = new DataTransfer()
      container.items.add(file)
      this.uploadFile(file)
      this.fieldTarget.files = container.files
      this.fieldTarget.dispatchEvent(new Event('change'))
      this.localStream.getTracks()[0].stop()
      this.recording = false
    })
  }

  submit() {
    this.formTarget.requestSubmit()
  }
  
  uploadFile(file) {
    // Form needs the file_field direct_upload: true, which
    // provides data-direct-upload-url
    const url = this.fieldTarget.dataset.directUploadUrl
    const upload = new DirectUpload(file, url)

    upload.create((error, blob) => {
      if (error) {
        console.log(blob)
        console.log(`Error: ${error}`)
      } else {
        const hiddenField = document.createElement("input")
        hiddenField.setAttribute("type", "hidden")
        hiddenField.setAttribute("value", blob.signed_id)
        // hiddenField.name = 'block[replies_attributes][1][audio]'
        hiddenField.name = this.fieldTarget.name
        this.formTarget.appendChild(hiddenField)
      }
    })
  }

  // Not used anymore
  drawSpectrum = function (analyser) {
    if (!this.hasWaveTarget) { return }
    var canvas = this.waveTarget,
      cwidth = canvas.width,
      cheight = canvas.height,
      meterWidth = 6,
      gap = 2,
      meterNum = cwidth / (meterWidth + gap),
      ctx = canvas.getContext('2d'),
      gradient = ctx.createLinearGradient(0, 0, 0, cheight)
    gradient.addColorStop(1, '#7928ca')
    gradient.addColorStop(0.5, '#ff0080')
    gradient.addColorStop(0, '#fff')
    ctx.fillStyle = gradient
    var drawMeter = function () {
      var array = new Uint8Array(analyser.frequencyBinCount)
      analyser.getByteFrequencyData(array)

      var step = Math.round(array.length / meterNum)
      ctx.clearRect(0, 0, cwidth, cheight)
      for (var i = 0; i < meterNum; i++) {
        var value = array[i * step]

        ctx.fillRect(i * (meterWidth + gap), cheight - value, meterWidth, cheight)
      }
      requestAnimationFrame(drawMeter)
    }
    requestAnimationFrame(drawMeter)
  }
}
