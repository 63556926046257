import { Controller } from '@hotwired/stimulus'
import { SineWaves } from 'sine-waves'

// Connects to data-controller="sinewave"
export default class extends Controller {
  static targets = ['waves']
  connect() {
    let speed = 0.5
    let a = [-2.5, -1.5, -2.8, 3, 2.8, -2.5]
    let color =150
    let secondColor = 50
    let opacity = 0.3

    if (this.wavesTarget.dataset['play'] === 'true') {
      speed = 5
      a = [-15, -5, -18, 20, 18, -15]
      color = 225
      secondColor = 200
      opacity = 0.75
    }

    var waves = new SineWaves({
      el: this.wavesTarget,
      speed: speed,
      ease: 'SineInOut',
      wavesWidth: '75%',
      waves: [
        { timeModifier: 4, lineWidth: 1, amplitude: a[0], wavelength: 25 },
        { timeModifier: 2, lineWidth: 1, amplitude: a[1], wavelength: 30 },
        { timeModifier: 1, lineWidth: 1, amplitude: a[2], wavelength: 30 },
        { timeModifier: 3, lineWidth: 1, amplitude: a[3], wavelength: 40 },
        { timeModifier: 0.5, lineWidth: 1, amplitude: a[4], wavelength: 60 },
        { timeModifier: 1.3, lineWidth: 1, amplitude: a[5], wavelength: 40 },
      ],
      // Called on window resize
      resizeEvent: function () {
        var gradient = this.ctx.createLinearGradient(0, 0, this.width, 0)
        gradient.addColorStop(0, `rgba(25, ${color}, ${color}, 0)`)
        gradient.addColorStop(0.5, `rgba(${color}, ${secondColor}, ${color}, ${opacity}`)
        gradient.addColorStop(1, `rgba(${color}, ${color}, 25, 0`)

        var index = -1
        var length = this.waves.length
        while (++index < length) {
          this.waves[index].strokeStyle = gradient
        }
        // Clean Up
        index = void 0
        length = void 0
        gradient = void 0
      },
    })
  }
}
