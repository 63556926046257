export const animation = {
  v: '5.5.7',
  meta: {
    g: 'LottieFiles AE 0.1.20',
    a: 'Jacopo Castellano',
    k: 'loading, circles, circle',
    d: '6 Loading Circles',
    tc: '',
  },
  fr: 40,
  ip: 0,
  op: 154,
  w: 500,
  h: 500,
  nm: 'The 6 Loading Circles',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Central Circle',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [250, 250, 0], ix: 2 },
        a: { a: 0, k: [61.43, 49.43, 0], ix: 1 },
        s: { a: 0, k: [63.75048828125001, 63.75048828125001, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [274.859, 274.859], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [61.43, 49.43], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 155,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Circle 6',
      sr: 1,
      ks: {
        o: { a: 0, k: 25, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
            { t: 150, s: [-360] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.172, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [250.97656249999994, 250.97656249999994, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { i: { x: 0.8, y: 0 }, o: { x: 0.333, y: 0 }, t: 60, s: [250, 250, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { t: 150, s: [250.97656249999994, 250.97656249999994, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-9.973, -5.973, 0], ix: 1 },
        s: { a: 0, k: [39.0625, 39.0625, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [864.055, 864.055], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.902, 0.176, 0.659], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-9.973, -5.973], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 155,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Circle 4',
      sr: 1,
      ks: {
        o: { a: 0, k: 25, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
            { t: 150, s: [-360] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.172, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [217.91015624999997, 301.8193359375, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.155, y: 0.921 },
              o: { x: 0.333, y: 0 },
              t: 60,
              s: [257.6611328125, 239.45556640625, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 150, s: [217.91015624999997, 301.8193359375, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-9.973, 27.393, 0], ix: 1 },
        s: { a: 0, k: [39.0625, 39.0625, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [864.055, 864.055], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.902, 0.176, 0.659], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-9.973, -5.973], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 155,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Circle 3',
      sr: 1,
      ks: {
        o: { a: 0, k: 25, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
            { t: 150, s: [360] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.172, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [253.0625, 218.75000000000006, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { i: { x: 0.155, y: 0.815 }, o: { x: 0.333, y: 0 }, t: 60, s: [250, 250, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { t: 150, s: [253.0625, 218.75000000000006, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-9.973, -5.973, 0], ix: 1 },
        s: { a: 0, k: [39.0625, 39.0625, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [864.055, 864.055], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.259, 0.322, 0.961], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-9.973, -5.973], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 155,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Circle 5',
      sr: 1,
      ks: {
        o: { a: 0, k: 25, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
            { t: 150, s: [360] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.172, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [289.55078125, 285.15625, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { i: { x: 0.155, y: 0.89 }, o: { x: 0.333, y: 0 }, t: 60, s: [250, 250, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { t: 150, s: [289.55078125, 285.15625, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-9.973, -5.973, 0], ix: 1 },
        s: { a: 0, k: [39.0625, 39.0625, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [864.055, 864.055], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.631, 0, 0.761], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-9.973, -5.973], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 155,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Circle 2',
      sr: 1,
      ks: {
        o: { a: 0, k: 25, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
            { t: 150, s: [-360] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.172, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [277.34375, 235.3515625, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.155, y: 0.651 },
              o: { x: 0.333, y: 0 },
              t: 60,
              s: [277.16943359375, 252.01611328125, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 150, s: [277.34375, 235.3515625, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-69.276, 30.735, 0], ix: 1 },
        s: { a: 0, k: [39.0625, 39.0625, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [864.055, 864.055], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.365, 0.263, 0.604], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-9.973, -5.973], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 155,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Circle 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 25, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
            { t: 150, s: [360] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.172, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [232.91015624999994, 259.765625, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.155, y: 0.585 },
              o: { x: 0.333, y: 0 },
              t: 60,
              s: [228.17480468750003, 246.54345703124994, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 150, s: [232.91015624999994, 259.765625, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [30.027, 34.027, 0], ix: 1 },
        s: { a: 0, k: [39.0625, 39.0625, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [864.055, 864.055], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.2, 0.373, 0.953], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-9.973, -5.973], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 155,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
}
