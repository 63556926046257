import { marked } from 'marked'
import { mangle } from 'marked-mangle'
import { gfmHeadingId } from 'marked-gfm-heading-id'

const slugger = new marked.Slugger()
const renderer = new marked.Renderer()

const mySluggerFunction = (header) => {
  return header.trim().replace(/\s+/g, '-').toLowerCase()
}
const options = {
  prefix: 'assista-',
}
renderer.heading = function (text, level, raw) {
  const slug = mySluggerFunction(raw)
  return `<h${level} id="${slug}">${text}</h${level}>`
}
marked.use(mangle(), gfmHeadingId(options), {
  renderer: renderer,
  highlighter: (code, language) => {
    const highlighted = language ? hljs.highlight(code, { language }).value : hljs.highlightAuto(code).value
    return `<pre><code class="hljs ${language}">${highlighted}</code></pre>`
  },
  languagePrefix: 'language-',
  breaks: true,
})

export { marked }