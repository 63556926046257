import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="command-done"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.element.classList.remove('animate__fadeIn')
      this.element.addEventListener('animationend', (event) => {
        this.element.remove()
      })
      this.element.classList.add('animate__fadeOut')
    }, 3000);
  }
}
