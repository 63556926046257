import { Controller } from "@hotwired/stimulus"
import { MakePostRequest } from '../utils'

// Connects to data-controller="rewardful"
export default class extends Controller {
  static targets = ["data", "convert"]

  connect() {
    const dataTarget = this.dataTarget.dataset.userRewardfulId
    const hasDataTarget = this.hasDataTarget
    rewardful('ready', function () {
      if (hasDataTarget && dataTarget === "" && Rewardful.referral) {
        console.log('Current referral ID: ', dataTarget)
        MakePostRequest('identity/rewardful', { rewardful_id: Rewardful.referral } ).then((r) => r.json()).then((r) => console.log(r))
      } else if (hasDataTarget && dataTarget !== "") {
        console.log(`Referral already set: ${dataTarget}`)
      } else {
        console.log('No referral present.')
      }
    })

    if (this.hasConvertTarget) {
      rewardful('convert', { email: this.convertTarget.dataset.userEmail })
    }
  }
}
