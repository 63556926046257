import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tabs"
export default class extends Controller {
  static targets = ["tab", "tabContent"]
  connect() {
  }

  click(event) {
    let ref = event.target.dataset['ref']
    this.tabTargets.map((tab) => {
      if (tab.dataset['ref'] === ref) {
        tab.classList.add('tab-active')
      } else {
        tab.classList.remove('tab-active')
      }
    })
    this.tabContentTargets.map((tabContent) => {
      if (tabContent.dataset['ref'] === ref) {
        tabContent.classList.remove('hidden')
      } else {
        tabContent.classList.add('hidden')
      }
    })
  }

  hasTarget(ref) {
    return this[`has${ref.charAt(0).toUpperCase() + ref.slice(1)}Target`]
  }

  target(ref) {
    return this[`${ref}Target`]
  }
}
