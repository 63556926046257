import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="example"
export default class extends Controller {
  connect() {
  }

  add(event) {
    let text = event.target.dataset.text.replaceAll('...',' ')
    document.getElementById('reply_body').value = text
  }
}
