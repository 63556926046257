import { StreamActions } from "@hotwired/turbo"
import { marked } from '../marked'

StreamActions.answer = function() {
  const message = this.getAttribute("message")
  const answerElement = document.getElementById('answer-content')
  const chat = document.getElementById('chat')
  if (answerElement) {
    answerElement.className = 'ml-3 relative text-sm py-2 px-4 shadow rounded-xl'
    div = document.createElement('div')
    div.className = 'markdown prose prose-sm break-words'
    
    div.innerHTML = marked.parse(message)

    answerElement.innerHTML = ''
    div.querySelectorAll('pre code').forEach((el) => {
      hljs.highlightElement(el)
    })
    answerElement.appendChild(div)
    chat.scrollTo(0, chat.scrollHeight)
  }
}
