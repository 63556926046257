import { Controller } from '@hotwired/stimulus'
import { MakeGetRequest } from '../utils'
import Chart from 'chart.js/auto'
import { getHoverColor, color } from 'chart.js/helpers'

let timeSaved = ''
const fetchData = async (agentId) => {
  const response = await MakeGetRequest(`/api/v1/agents/${agentId}/actions_chart`)
  const result = await response.json()
  return result
}

const donutLabel = {
  id: 'donutLabel',
  beforeDatasetDraw(chart, args, pluginOptions) {
    const { ctx, data } = chart

    ctx.save()

    // Drawing hours saved e.g. '1.4hrs'
    const xCoordinate = chart.getDatasetMeta(0).data[0].x
    const yCoordinate = chart.getDatasetMeta(0).data[0].y
    ctx.font = '24px sans-serif'
    ctx.fillStyle = '#f5f5f5'
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.fillText(timeSaved, xCoordinate, yCoordinate)

    // Drawing 'saved' word
    ctx.font = '14px sans-serif'
    ctx.fillStyle = 'rgba(255, 255, 255, 0.6)'
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.fillText('saved', xCoordinate, yCoordinate + 20)
  },
}

const cache = new Map()
let width = null
let height = null

function createRadialGradient3(context, c1, c2, c3) {
  const chartArea = context.chart.chartArea
  if (!chartArea) {
    // This case happens on initial chart load
    return
  }

  const chartWidth = chartArea.right - chartArea.left
  const chartHeight = chartArea.bottom - chartArea.top
  if (width !== chartWidth || height !== chartHeight) {
    cache.clear()
  }
  let gradient = cache.get(c1 + c2 + c3)
  if (!gradient) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width = chartWidth
    height = chartHeight
    const centerX = (chartArea.left + chartArea.right) / 2
    const centerY = (chartArea.top + chartArea.bottom) / 2
    const r = Math.min((chartArea.right - chartArea.left) / 2, (chartArea.bottom - chartArea.top) / 2)
    const ctx = context.chart.ctx
    gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, r)
    gradient.addColorStop(0, c1)
    gradient.addColorStop(0.5, c2)
    gradient.addColorStop(1, c3)
    cache.set(c1 + c2 + c3, gradient)
  }

  return gradient
}

const mockData = [
  {
    action_name: 'Empty Chart',
    action_count: 1,
    color: 'gray',
  },
]

// Connects to data-controller="charts"
export default class extends Controller {
  static targets = ['actionsChart']
  connect() {}

  actionsChartTargetConnected(element) {
    fetchData(element.dataset.agentId).then((result) => {
      let data = result['actionsChart'].length > 0 ? result['actionsChart'] : mockData
      timeSaved = `${result['timeSaved']}hrs`

      new Chart(this.actionsChartTarget, {
        type: 'doughnut',
        data: {
          labels: data.map((row) => row.action_name),
          datasets: [
            {
              label: 'Actions',
              data: data.map((row) => row.action_count),
            },
          ],
        },
        plugins: [donutLabel],
        options: {
          cutout: 75,
          elements: {
            arc: {
              borderWidth: 1,
              backgroundColor: (context) => {
                let colors = data.map((row) => row.color)
                let c = colors[context.dataIndex]
                if (!c) {
                  return
                }
                if (context.active) {
                  c = getHoverColor(c)
                }
                const mid = color(c).desaturate(0.1).rgbString()
                const start = color(c).lighten(0.9).rotate(270).rgbString()
                const end = color(c).lighten(0.5).rgbString()
                return createRadialGradient3(context, start, mid, end)
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      })
    })
  }
}
