import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle"
export default class extends Controller {
  static targets = ["checkButton", "uncheckButton"]

  connect() {
  }

  click(event) {
    if (event.target.checked) {
      this.checkButtonTarget.click()
    } else {
      this.uncheckButtonTarget.click()
    }
  }
}
