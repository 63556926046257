import React from 'react';
import { CarbonConnect } from 'carbon-connect';

const CarbonConnectComponent = ({customerId}) => {

  const tokenFetcher = async () => {
    try {
      const response = await fetch(`/api/v1/carbon/fetch_token?customer_id=${customerId}`)
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`)
      }
      const data = await response.json()

      return data // Must return data containing access_token
    } catch (error) {
      console.error('Error fetching the token:', error)
    }
  }

  return (
    <CarbonConnect
      orgName="Your Organization"
      // brandIcon="/path/to/your/brand/icon"
      embeddingModel="OPENAI_ADA_LARGE_1024"
      tokenFetcher={tokenFetcher}
      tags={{
        tag1: 'tag1_value',
        tag2: 'tag2_value',
        tag3: 'tag3_value',
      }}
      maxFileSize={10000000}
      enabledIntegrations={[
        {
          id: 'LOCAL_FILES',
          chunkSize: 100,
          overlapSize: 10,
          maxFileSize: 20000000,
          allowMultipleFiles: true,
          maxFilesCount: 5,
          allowedFileTypes: [
            { extension: 'csv', chunkSize: 1200, overlapSize: 120, embeddingModel: 'OPENAI' },
            { extension: 'txt', chunkSize: 1599, overlapSize: 210, embeddingModel: 'AZURE_OPENAI' },
            { extension: 'pdf' },
          ],
        },
        {
          id: 'GOOGLE_DRIVE',
          chunkSize: 1000,
          overlapSize: 20,
          fileSyncConfig: {
            detect_audio_language: true,
            split_rows: true,
            generate_chunks_only: true,
          },
        },
        {
          id: 'NOTION',
          chunkSize: 1000,
          overlapSize: 20,
          fileSyncConfig: {
            detect_audio_language: true,
            split_rows: true,
            generate_chunks_only: true,
          },
        },
      ]}
      onSuccess={(data) => console.log('Data on Success:', data)}
      onError={(error) => console.log('Error:', error)}
      primaryBackgroundColor="#F2F2F2"
      primaryTextColor="#555555"
      secondaryBackgroundColor="#f2f2f2"
      secondaryTextColor="#000000"
      allowMultipleFiles={true}
      open={true}
      chunkSize={1500}
      overlapSize={20}
    />
  )
};

export default CarbonConnectComponent;
