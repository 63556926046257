import { Controller } from "@hotwired/stimulus"
import { MakePostRequest } from "../utils"
import hljs from 'highlight.js'
import { marked } from "../marked"

// Connects to data-controller="chat"
export default class extends Controller {
  static targets = ["question", "chat", "submitContainer", "typingContainer", "scrollRef", "toHighlight"]
  static connected = false
  connect() {
    this.connected = true
  }

  scrollToBottom() {
    this.chatTarget.scrollTo(0, this.chatTarget.scrollHeight)
  }

  scrollRefTargetConnected(element) {
    element.scrollIntoView()
  }

  questionTargetConnected(element) {
    if (this.connected && element.dataset === "false") {
      this.scrollToBottom()
    }
    const typingId = `#typing_${element.id}`
    const typingElement = element.querySelector(typingId)
    if (typingElement) {
      this.showTyping()
      requestAnswer(element.id)
        .then((result) => {
          typingElement.innerHTML = result
          this.hideTyping()
        })
        .catch((error) => {
          this.hideTyping()
        })
    }
    this.scrollToBottom()
  }

  showTyping() {
    this.submitContainerTarget.style.display = "none"
    this.typingContainerTarget.style.display = "block"
  }
  hideTyping() {
    this.submitContainerTarget.style.display = "block"
    this.typingContainerTarget.style.display = "none"
  }
  toHighlightTargetConnected(element) {
    element.innerHTML = marked.parse(element.textContent)
    element.querySelectorAll('pre code').forEach((el) => {
      hljs.highlightElement(el)
    })
  }
}

const requestAnswer = async (questionId) => {
  const intId = parseInt(questionId.split('question_')[1])
  const response = await MakePostRequest(`/api/v1/questions/${intId}/answer`)
  const result = await response.text()
  return result
}
