import { StreamActions } from '@hotwired/turbo'

StreamActions.progress = function () {
  const status = JSON.parse(this.getAttribute('status'))
  const progressLoad = document.getElementById('progress-load')
  const progressStep = document.getElementById('progress-step')

  progressStep.textContent = status.step

  const progressPercentage = (status.progress / status.total) * 100
  progressLoad.setAttribute('x-data', `{ circumference: 50 * 2 * Math.PI, percent: ${Math.round(progressPercentage)} }`)
}
