import { Controller } from '@hotwired/stimulus'
import { driver } from 'driver.js'
import { MakePatchRequest } from '../utils'

// Connects to data-controller="getting-started"
export default class extends Controller {
  static targets = ['personal', 'workspace']
  connect() {}

  confirmMessage = 'Are you sure you want to exit the Getting started flow? You will not be able to access it again.'

  personalTargetConnected(element) {
    const driverObj = driver({
      showProgress: true,
      steps: [
        {
          element: '#diver-step-1',
          popover: { title: 'Workspaces', description: 'You can switch between workspaces or create your own.' },
        },
        {
          element: '#diver-step-2',
          popover: {
            title: 'Avatars',
            description: 'You can chat with any specialized AI Avatar. Each conversation is scoped by workspaces.',
          },
        },
        {
          element: '#diver-step-3',
          popover: {
            title: 'Chat history',
            description: 'You will be able to find all you conversations with the AI Avatars scoped by workspaces.',
          },
        },
        {
          element: '#diver-step-4',
          popover: {
            title: 'Your own AI',
            description: 'To create your own AI you must create a workspace and add your documents.',
          },
        },
      ],
      onDestroyStarted: () => {
        if (!driverObj.hasNextStep() || confirm(this.confirmMessage)) {
          driverObj.destroy()
          MakePatchRequest(`/api/v1/users/${element.dataset.userId}/getting_started_flow`, { flow: 'personal' })
        }
      },
    })
    driverObj.drive()
  }

  workspaceTargetConnected(element) {
    const driverObj = driver({
      showProgress: true,
      steps: [
        {
          element: '#diver-step-5',
          popover: {
            title: 'Workspace dashboard',
            description: 'Welcome to your workspace dashboard. You should start by adding your first document.',
          },
        },
        {
          element: '#diver-step-6',
          popover: {
            title: 'Publishing documents',
            description: 'After you upload your documents, you must publish them to make them available to the AI.',
          },
        },
        {
          element: '#diver-step-7',
          popover: {
            title: 'Interacting with your AI Lumio',
            description:
              'After publishing your document, you are ready to ask your questions to your AI. Click the AI Lumio button or use the link.',
          },
        },
        {
          element: '#diver-step-8',
          popover: { title: 'Integrations', description: 'You can integrate your AI in different ways.' },
        },
        {
          element: '#diver-step-9',
          popover: { title: 'Members', description: 'You can manage the workspace members.' },
        },
      ],
      onDestroyStarted: () => {
        if (!driverObj.hasNextStep() || confirm(this.confirmMessage)) {
          driverObj.destroy()
          MakePatchRequest(`/api/v1/users/${element.dataset.userId}/getting_started_flow`, { flow: 'workspace' })
        }
      },
    })
    driverObj.drive()
  }
}
