import { Controller } from '@hotwired/stimulus'
import { MakePostRequest } from '../utils'

// Connects to data-controller="activity-feed"
export default class extends Controller {
  static targets = ['activityFeed', 'activityFeedCount']

  connect() {
    if (this.hasActivityFeedTarget) {
      this.requestRetrospectiveActivities(false)
      setInterval(() => {
        this.requestPrevMinuteActivities(false)
      }, 60000)
    }
    if (this.hasActivityFeedCountTarget) {
      this.requestRetrospectiveActivities(true)
      setInterval(() => {
        this.requestPrevMinuteActivities(true)
      }, 60000)
    }
  }

  requestPrevMinuteActivities(count) {
    MakePostRequest(`/api/v1/activity_feed/prev_minute${count && "?count=true"}`, {})
      .then((r) => r.text())
      .then((html) => {
        if (count) {
          this.activityFeedCountTarget.innerHTML = html
        } else {
          this.activityFeedTarget.insertAdjacentHTML('beforebegin', html)
        }
      })
  }

  requestRetrospectiveActivities(count) {
    MakePostRequest(`/api/v1/activity_feed/retrospective${count && '?count=true'}`, {})
      .then((r) => r.text())
      .then((html) => {
        if (count) {
          this.activityFeedCountTarget.innerHTML = html
        } else {
          this.activityFeedTarget.insertAdjacentHTML('beforebegin', html)
        }
      })
  }

  addPrefix(event) {
    const dataset = event.target.nodeName === 'BUTTON' ? event.target.dataset : event.target.closest('button').dataset

    const threadId = dataset.threadId
    const messageId = dataset.externalId
    const name = dataset.activityName
    const body = dataset.activityBody

    const inputElement = this.agentInputField()


    if (threadId) {
      inputElement.parentElement.classList.remove('hidden')
      inputElement.value = `Reply to email Thread with id: ${threadId}, and Message with id: ${messageId}.\n
      CONTEXT: """Email | Original Subject: ${name} \n\n ${body}"""
      \n\nYou have to formulate a well written email to reply and deliver the message: `
    }

    document.querySelector('turbo-frame#modal').innerHTML = ''
  }

  removePrefix() {
    const inputElement = this.agentInputField()

    inputElement.parentElement.classList.add('hidden')
    inputElement.value = ''
  }

  agentInputField() {
    return document.getElementById('agent-input-field')
  }
}
