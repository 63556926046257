export function headers() {
  return new Headers({
    'Content-Type': 'application/json',
    'X-CSRF-Token': document.getElementsByTagName('meta')['csrf-token'].content,
  })
}

function makeRequest(verb, endPoint, data) {
  return fetch(endPoint, {
    headers: headers(),
    credentials: 'include',
    method: verb,
    body: JSON.stringify(data),
  })
}

export function MakeGetRequest(endPoint) {
  return fetch(endPoint, {
    headers: headers(),
    credentials: 'include',
    method: 'GET',
  })
}

export function MakePostRequest(endPoint, data) {
  return makeRequest('POST', endPoint, data)
}

export function MakePatchRequest(endPoint, data) {
  return makeRequest('PATCH', endPoint, data)
}

export function MakeDeleteRequest(endPoint, data) {
  return makeRequest('DELETE', endPoint, data)
}
